import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    CorporateClients: [], Branchs:[]
  },
  getters: {
    getCorporateClients(state) {
      return state.CorporateClients;
    },getBranches(state) {
      return state.Branchs;
    },
  },
  mutations: {
    SetCorporateCilents(state, data) {
      state.CorporateClients = [];
      state.CorporateClients = data;
    },SetBranches(state, data) {
      state.Branchs = [];
      state.Branchs = data;
    },
  },
  actions: {
    getCorporateClients({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `CorporateClients/getCorporateClients`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
    
          commit("SetCorporateCilents", res.data);
        })
        .catch((err) => console.log(err));});
    },

    getBranchesByCorporateId({ commit }, ) {
      console.log("come");
      return new Promise((resolve, rej) => {
      api()
        .post(
          `CorporateClients/getBranchesByCorporateId`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBranches", res.data);
        })
        .catch((err) => console.log(err));});
    },



    addCorporateClient({ }, formData) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateClients/addCorporateClient`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            console.log(res)
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editCorporateClient(
      { },
      formData
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateClients/editCorporateClient`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCorporateClient({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateClients/deleteCorporateClient`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    UploadEmployeeFile({ }, Formdata) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateEmployees/UploadEmployeeFile`,
            Formdata,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    removeEmployeeFile({ }, {CorporateEmployeesId,docNameId}) {
  return new Promise((resolve, rej) => {
    api()
      .post(
        `CorporateEmployees/removeEmployeeFile`,
        { docNameId: docNameId,CorporateEmployeesId:CorporateEmployeesId },
        {
          headers: {
            Authorization: localStorage.getItem("JwtToken"),
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
},




    
  },
};
