import {
  createRouter,
   createWebHashHistory,
  //createWebHistory,
} from "vue-router";
// import jwt_decode from 'jwt-decode';

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem("JwtToken")) {
    next();
    return;
  }
  next("/");
};

const checkGroupPermission = (requiredGroupIds) => {
  return (to, from, next) => {
    const GID = localStorage.getItem("GID");
    if (GID) {
      const groupId = Number(GID);
      if (requiredGroupIds.includes(groupId)) {
        next();
      } else {
        next("/"); // Redirect to home or an error page
      }
    } else {
      next("/"); // Redirect to home or login page if token is missing
    }
  };
};

const routes = [
  {
    path: "/",
    name: "login",
    meta: { title: 'login' },

    component: () => import("../views/loginView.vue"),
  },
  {
    path: "/home",
    name: "home",
    meta: { title: 'home' },

    beforeEnter: ifAuthenticated,
    component: () => import("../views/HomeView.vue"),
    children: [
      {
        path: "/routesMap",
        name: "routesMap",
        meta: { title: 'routesMap' },

        component: () => import("../views/routesMapView.vue"),
        beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },
      {
        path: "/branches",
        name: "branches",
        meta: { title: 'branches' },

        component: () => import("../views/branches/branchesView.vue"),
        //beforeEnter: checkGroupPermission([6, 8]), // Example group IDs with access
        beforeEnter: checkGroupPermission([1,12]), // Example group IDs with access
      },
      {
        path: "/chat",
        name: "chat",
        meta: { title: 'chat' },

        component: () => import("../views/chatView.vue"),
        beforeEnter: checkGroupPermission([6, 8]), // Example group IDs with access
      },
      {
        path: "/stops",
        name: "stop points",
        meta: { title: 'stop points' },

        component: () => import("../views/stopsView.vue"),
        beforeEnter: checkGroupPermission([1, 10, 4, 12, 14]), // Example group IDs with access
      },
      {
        path: "/routes",
        name: "route",
        meta: { title: 'route' },

        component: () => import("../views/routesView.vue"),
        beforeEnter: checkGroupPermission([12, 4, 10, 1, 14]), // Example group IDs with access
      },
      {
        path: "/countries",
        name: "countries",
        meta: { title: 'countries' },

        component: () => import("../views/region/countriesView.vue"),
        beforeEnter: checkGroupPermission([4, 12, 1]), // Example group IDs with access
      },
      {
        path: "/governates",
        name: "governates",
        meta: { title: 'governates' },

        component: () => import("../views/region/governateView.vue"),
        beforeEnter: checkGroupPermission([1, 4, 12]), // Example group IDs with access
      },
      {
        path: "/areas",
        name: "areas",
        meta: { title: 'areas' },

        component: () => import("../views/region/areaView.vue"),
        beforeEnter: checkGroupPermission([12, 4, 1]), // Example group IDs with access
      },
      {
        path: "/busType",
        name: "busType",
        meta: { title: 'Bus Type' },

        component: () => import("../views/MainData/busTypeView.vue"),
        beforeEnter: checkGroupPermission([5, 1, 10, 12, 5]), // Example group IDs with access
      },
      {
        path: "/color",
        name: "color",
        meta: { title: 'color' },

        component: () => import("../views/MainData/colorView.vue"),
        beforeEnter: checkGroupPermission([5, 12, 1]), // Example group IDs with access
      },
      {
        path: "/docName",
        name: "docName",
        meta: { title: 'Doc Name' },

        component: () => import("../views/MainData/docNameView.vue"),
        beforeEnter: checkGroupPermission([1, 12, 5]), // Example group IDs with access
      },
      {
        path: "/make",
        name: "make",
        meta: { title: 'make' },

        component: () => import("../views/MainData/makeView.vue"),
        beforeEnter: checkGroupPermission([5, 12, 1]), // Example group IDs with access
      },
      {
        path: "/model",
        name: "model",
        meta: { title: 'model' },

        component: () => import("../views/MainData/modelView.vue"),
        beforeEnter: checkGroupPermission([1, 5, 12]), // Example group IDs with access
      },
      {
        path: "/vehicleTypes",
        name: "vehicleTypes",
        meta: { title: 'Vehicle Types ' },

        component: () => import("../views/MainData/vehicleTypesView.vue"),
        beforeEnter: checkGroupPermission([5, 12, 1]), // Example group IDs with access
      },
      {
        path: "/year",
        name: "year",
        meta: { title: 'year' },

        component: () => import("../views/MainData/yearView.vue"),
        beforeEnter: checkGroupPermission([5, 12, 1]), // Example group IDs with access
      },
      {
        path: "/Bus",
        name: "Bus",
        meta: { title: 'Bus ' },

        component: () => import("../views/BusView.vue"),
        beforeEnter: checkGroupPermission([1, 12, 5]), // Example group IDs with access
      },
      {
        path: "/rideCategory",
        name: "rideCategory",
        meta: { title: 'Ride Category' },

        component: () => import("../views/MainData/rideCategoryView"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },

      {
        path: "/captains",
        name: "Captains",
        meta: { title: 'Captains' },

        component: () => import("../views/MainData/captainsView"),
        beforeEnter: checkGroupPermission([1, 10, 5, 12]), // Example group IDs with access
      },
      {
        path: "/contractors",
        name: "Contractors",
        meta: { title: 'Contractors' },

        component: () => import("../views/MainData/contractorsView"),
        beforeEnter: checkGroupPermission([1, 10, 5, 12]), // Example group IDs with access
      },
      {
        path: "/users",
        name: "users",
        meta: { title: 'users' },

        component: () => import("../views/admin/usersView.vue"),
        beforeEnter: checkGroupPermission([1]), // Example group IDs with access
      },
      {
        path: "/pages",
        name: "pages",
        meta: { title: 'pages' },

        component: () => import("../views/admin/pagesView.vue"),
        beforeEnter: checkGroupPermission([1]), // Example group IDs with access
      },
      {
        path: "/groups",
        name: "groups",
        meta: { title: 'groups' },

        component: () => import("../views/admin/groupsView.vue"),
        beforeEnter: checkGroupPermission([1]), // Example group IDs with access
      },
      {
        path: "/groupPages",
        name: "groupPages",
        meta: { title: 'Group Pages' },

        component: () => import("../views/admin/groupPageView.vue"),
        beforeEnter: checkGroupPermission([1]), // Example group IDs with access
      },
      {
        path: "/schedules",
        name: "schedules",
        meta: { title: 'schedules' },

        component: () => import("../views/scheduleView.vue"),
        beforeEnter: checkGroupPermission([1, 10, 4, 12]), // Example group IDs with access
      },
      

      {
        path: "/realRides",
        name: "realRides",
        meta: { title: 'Real Rides' },

        component: () => import("../views/realRidesView.vue"),
        beforeEnter: checkGroupPermission([1, 10, 6, 12, 5,4]), // Example group IDs with access
      },
      {
        path: "/realRidesBusType",
        name: "realRidesBusType",
        meta: { title: 'Live Ops' },

        component: () => import("../views/realRidesBusTypeView.vue"),
        beforeEnter: checkGroupPermission([1, 10, 6, 12, 5]), // Example group IDs with access
      },
      {
        path: "/package",
        name: "package",
        meta: { title: 'package' },

        component: () => import("../views/package/packageView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/realRidesStretch",
        name: "realRidesStretch",
        meta: { title: 'Real Rides Stetch' },

        component: () => import("../views/realRidesStretchView"),
        beforeEnter: checkGroupPermission([1, 4, 10, 12]), // Example group IDs with access
      },
      {
        path: "/realRidesForSupply",
        name: "realRidesForSupply",
        meta: { title: 'real Rides Supply' },

        component: () => import("../views/realRidesForSupplyView.vue"),
        beforeEnter: checkGroupPermission([1, 5, 10, 12]), // Example group IDs with access
      },
      {
        path: "/dediction",
        name: "dediction",
        meta: { title: 'dediction' },

        component: () => import("../views/MainData/dedictionView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/captainChangeReasions",
        name: "captainChangeReasions",
        meta: { title: 'Captain Change Reasions' },

        component: () =>
          import("../views/MainData/captainChangeReasionsView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },

      {
        path: "/employees",
        name: "employees",
        meta: { title: 'Employees' },

        component: () => import("../views/MainData/employeesView.vue"),
        beforeEnter: checkGroupPermission([1]), // Example group IDs with access
      },
      {
        path: "/cancelReasons",
        name: "cancelReasons",
        meta: { title: 'Cancel Reasons' },

        component: () => import("../views/MainData/cancelReasonsView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/startEndReasons",
        name: "startEndReasons",
        meta: { title: 'Start End Reasons' },

        component: () => import("../views/MainData/startEndReasonsView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/captainTransactions",
        name: "captainTransactions",
        meta: { title: 'Captain Transactions' },

        component: () =>
          import("../views/captainTransactions/captainTransactions.vue"),
        beforeEnter: checkGroupPermission([1, 12, 10, 5, 6]), // Example group IDs with access
      },
      {
        path: "/users",
        name: "users",
        meta: { title: 'users' },

        component: () => import("../views/MainData/usersView.vue"),
        beforeEnter: checkGroupPermission([1, 8, 9, 12]), // Example group IDs with access
      },
      {
        path: "/contractorTransaction",
        name: "contractorTransaction",
        meta: { title: 'Contractor Transaction' },

        component: () =>
          import(
            "../views/contractorTransaction/contractorTransactionView.vue"
          ),
        beforeEnter: checkGroupPermission([1, 10, 12, 5]), // Example group IDs with access
      },


      {
        path: "/addNotificationsUser",
        name: "add Notifications to  User",
        meta: { title: 'Add Notifications to  User' },

        component: () => import("../views/addNotificationsUserVue.vue"),
        beforeEnter: checkGroupPermission([1, 10, 12]), // Example group IDs with access
      },

      {
        path: "/scheduledRunSchedules",
        name: "scheduled Run Schedules",
        meta: { title: 'scheduled Run Schedules' },

        component: () =>
          import("../views/scheduledRunSchedules/scheduledRunSchedules.vue"),
        beforeEnter: checkGroupPermission([1, 4, 10, 12]), // Example group IDs with access
      },
      {
        path: "/userCancellationReason",
        name: "User Cancellation Reason",
        meta: { title: 'User Cancellation Reason' },

        component: () =>
          import("../views/MainData/userCancellationReasonView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/compensation",
        name: "compensation",
        meta: { title: 'compensation' },

        component: () => import("../views/MainData/compensationView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/tabPopup/:id", // Define route with parameter ':id'
        name: "tabPopup",

        component: () => import("../components/realRidesBusType/tabPopup.vue"),
      },
      {
        path: "/captainDetaials/:id", // Define route with parameter ':id'
        name: "captainDetaials",
        meta: { title: 'Captain Detaials' },

        component: () =>
          import("../components/Captain/pop/captainDetaialsPopup.vue"),
      },
      {
        path: "/contractorInvoice/:id", // Define route with parameter ':id'
        name: "contractorInvoice",
        meta: { title: 'Contractor Invoice' },

        component: () =>
          import("../components/contractorTransaction/contractorInvoice.vue"),
        beforeEnter: checkGroupPermission([1, 12, 10, 5]), // Example group IDs with access
      },
      {
        path: "/captainTransactionsPage/:id", // Define route with parameter ':id'
        name: "captainTransactionsPage",
        meta: { title: 'Captain Transactions' },

        component: () =>
          import(
            "../components/captainTransactions/captainTransactionsPage.vue"
          ),
        beforeEnter: checkGroupPermission([1, 12, 10, 5]), // Example group IDs with access
      },
      {
        path: "/printInvoiceForCaptain/:id", // Define route with parameter ':id'
        name: "printInvoiceForCaptain",
        meta: { title: 'printInvoiceForCaptain' },

        component: () =>
          import(
            "../components/contractorTransaction/printInvoiceForCaptain.vue"
          ),
        beforeEnter: checkGroupPermission([1, 12, 10, 5]), // Example group IDs with access
      },
      {
        path: "/changePassword", // Define route with parameter ':id'
        name: "changePassword",
        meta: { title: 'changePassword' },

        component: () => import("../views/changePasswordView.vue"),
      },
      {
        path: "/oldchat/:id", // Define route with parameter ':id'
        name: "chat history ",
        meta: { title: 'chat history' },

        component: () => import("../components/chatOldView/oldChatView.vue"),
      },
      {
        path: "/profilePop/:id", // Define route with parameter ':id'
        name: "profilePop",
        meta: { title: 'profilePop' },

        component: () => import("../components/profile/profilePop.vue"),
      },
      {
        path: "/invoiceforContarctor",
        name: "invoiceforContarctor",
        meta: { title: 'Invoice for Contarctor' },

        component: () =>
          import(
            "../components/contractorTransaction/invoiceforContarctor.vue"
          ),
        beforeEnter: checkGroupPermission([11]), // Example group IDs with access
      },
      {
        path: "/closedChats",
        name: "closed chats",
        meta: { title: 'closed chats' },

        component: () => import("../views/chatClosedView.vue"),
        beforeEnter: checkGroupPermission([6, 8]), // Example group IDs with access
      },
      {
        path: "/chatsWaiting",
        name: "chats waiting to close",
        meta: { title: 'chats waiting to close' },

        component: () => import("../views/chatWaitingToCloseView.vue"),
        beforeEnter: checkGroupPermission([6, 8]), // Example group IDs with access
      },
      {
        path: "/redirectedChats",
        name: "Redirected chats",
        meta: { title: 'Redirected chats' },

        component: () => import("../views/chatRedirectionView.vue"),
        beforeEnter: checkGroupPermission([6, 8]), // Example group IDs with access
      },
      {
        path: "/corporates",
        name: "corporates",
        meta: { title: 'corporates' },

        component: () => import("../views/corporates/corporatesView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/corporateEmployees",
        name: "corporateEmployees",
        meta: { title: 'corporateEmployees' },

        component: () => import("../views/corporates/corporateEmployeesView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },
      {
        path: "/corporateClients",
        name: "corporateClients",
        meta: { title: 'corporateClients' },

        component: () => import("../views/corporates/corporateClientsView.vue"),
        beforeEnter: checkGroupPermission([15]), // Example group IDs with access

      },
      {
        path: "/tripsRoute",
        name: "tripsRoute",
        meta: { title: 'tripsRoute' },

        component: () => import("../views/trips/tripsRouteView.vue"),
        beforeEnter: checkGroupPermission([15]), // Example group IDs with access

      },

      {
        path: "/stopPersons",
        name: "stopPersons",
        meta: { title: 'stopPersons' },

        component: () => import("../views/stopPersons/stopPersonsView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      }, {
        path: "/contract",
        name: "contract",
        meta: { title: 'contract' },

        component: () => import("../views/contractView.vue"),
        beforeEnter: checkGroupPermission([1, 12]), // Example group IDs with access
      },{
        path: "/corporatesDetail",
        name: "corporatesDetail",
        meta: { title: 'corporatesDetail' },

        component: () => import("../views/admin/corporatesDetailPageView.vue"),
        beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },
      {
        path: "/ClientsNotifications",
        name: "ClientsNotifications",
        meta: { title: 'corporatesDetail' },

        component: () => import("../views/corporates/corporateClientsNotifivationsView.vue"),
        beforeEnter: checkGroupPermission([15]), // Example group IDs with access
      },

    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';
  next();
});

export default router;