import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Corporates: [], CorporatesDoc:[],CorporatesDrop:[]
  },
  getters: {
    getCorporates(state) {
      return state.Corporates;
    },getCorporatesDoc(state) {
      return state.CorporatesDoc;
    },getCorporatesDrop(state) {
      return state.CorporatesDrop;
    },
  },
  mutations: {
    SetCorporates(state, data) {
      state.Corporates = [];
      state.Corporates = data;
    },SetCorporatesDoc(state, data) {
      state.CorporatesDoc = [];
      state.CorporatesDoc = data;
    },SetCorporatesDrop(state, data) {
      state.CorporatesDrop = [];
      state.CorporatesDrop = data;
    },
  },
  actions: {
    fetchAllCorporates({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Corporates/getCorporates`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCorporates", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getCorporatesDrop({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Corporates/getCorporatesDrop`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCorporatesDrop", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getCorporatesLogo({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Corporates/getCorporatesLogo`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCorporatesDrop", res.data);
        })
        .catch((err) => console.log(err));});
    },

    
    getCorporatesById({ commit },id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Corporates/getCorporatesById`,
          {id:id},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCorporates", res.data);
        })
        .catch((err) => console.log(err));});
    },

    
    get_corporateDocs_by_corporateId({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Corporates/get_corporateDocs_by_corporateId`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetCorporatesDoc", res.data);
        })
        .catch((err) => console.log(err));});
    },



    addCorporate({ }, formData) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Corporates/addCorporate`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editeCorporate(
      { },
      formData
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Corporates/editeCorporate`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCorporate({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Corporates/deleteCorporate`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    UploadCorporateFile({ }, Formdata) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Corporates/UploadCorporateFile`,
            Formdata,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },






    
    removeCorporateFile({ }, {corporateId,docNameId}) {
  return new Promise((resolve, rej) => {
    api()
      .post(
        `Corporates/removeCorporateFile`,
        { docNameId: docNameId,corporateId:corporateId },
        {
          headers: {
            Authorization: localStorage.getItem("JwtToken"),
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
},




    
  },
};
