import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    User: [],
    Trips: [],
    UserbyGropingDate: [],
    UserSearch: [],
    UserNotifications: [],
    UserByNameorPhone: [],
    UserTransferLog: [],

  },
  getters: {
    getUser(state) {
      return state.User;
    }, getTrips(state) {
      return state.Trips;
    }, getUserbyGropingDate(state) {
      return state.UserbyGropingDate;
    }, getUserSearch(state) {
      return state.UserSearch;
    },getUserNotifications(state) {
      return state.UserNotifications;
    },getUserByNameorPhone(state) {
      return state.UserByNameorPhone;
    },getUserTransferLog(state) {
      return state.UserTransferLog;
    },
  },
  mutations: {
    SetUser(state, data) {
      state.User = [];
      state.User = data;
    }, SetTrips(state, data) {
      state.Trips = [];
      state.Trips = data.sort((a, b) => Number(b.id) - Number(a.id));
    }, SetUserbyGropingDate(state, data) {
      state.UserbyGropingDate = [];
      state.UserbyGropingDate = data;
    }, SetUserSearch(state, data) {
      state.UserSearch = [];
      state.UserSearch =   data.sort((a, b) => Number(b.id) - Number(a.id));
;
    },SetUserNotifications(state, data) {
      state.UserNotifications = [];
      state.UserNotifications = data.sort((a, b) => Number(b.serial) - Number(a.serial));

    },SetUserByNameorPhone(state, data) {
      state.UserByNameorPhone = [];
      state.UserByNameorPhone = data.length > 0 ? data.slice(0, 1) : [];
    },SetUserTransferLog(state, data) {
      state.UserTransferLog = [];
      state.UserTransferLog = data;
    },
  },
  actions: {
    getAllUser({ commit }) {

      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getAllUser`,
            {},
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUser", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getAllUserbyGropingDate({ commit }) {
      api().post(`users/getAllUserbyGropingDate`, {}, {
        headers: {
          'Authorization': localStorage.getItem("JwtToken")
        }
      })
        .then(res => {
          commit("SetUserbyGropingDate", res.data);
        })
        .catch(err => {
        })

    },
    getUserTripsById({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserTripsById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetTrips", res.data);
          })
          .catch((err) => console.log(err));
      })
    },

    getUserByNameorPhone({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserByNameorPhone`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserByNameorPhone", res.data);
          })
          .catch((err) => resolve(err.response.data));
      })
    },


    
    getUserSearchById({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserSearchById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserSearch", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserNotificationsById({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `users/getUserNotificationsById`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserNotifications", res.data);
          })
          .catch((err) => console.log(err));
      })
    },
    getUserTransferLogs({ commit }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `userTransferLog/getUserTransferLogs`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
            commit("SetUserTransferLog", res.data);
          })
          .catch((err) => console.log(err));
      })
    }
    
  },
};
