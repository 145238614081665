import api from '../../api';
export default {
    namespaced: true,
    state: {
        CorporatesDetail: [],
        EmployeeBooking: [],
        corporate:[],
        corporateRoutes: [],
        corporateEmpolyees: [],
        corporateBranchs: [],
        corporateMissingTrips: [],
        corporateRides: [],
        LateTrips: [],

    },
    getters: {
        getCorporatesDetail(state) {
            return state.CorporatesDetail;
        },
         getEmployeeBooking(state) {
            return state.EmployeeBooking;
        },
        getCorporate(state) {
            return state.corporate;
        },
        getCorporateRoutes(state) {
            return state.corporateRoutes;
        },
        getCorporateEmployees(state) {
            return state.corporateEmpolyees;
        },
        getCorporateBranchs(state) {
            return state.corporateBranchs;
        },
        getCorporateMissingTrips(state) {
            return state.corporateMissingTrips;
        },
        getCorporateRides(state) {
            return state.corporateRides;
        },getLateTrips(state) {
            return state.LateTrips;
        },
    },
    mutations: {
        SetCorporatesDetail(state, data) {            
            state.CorporatesDetail = [];
            state.CorporatesDetail = data;
        },  SetEmployeeBooking(state, data) {            
            state.EmployeeBooking = [];
            state.EmployeeBooking = data.map(ride => ({
                ...ride,
                stopId: Number(ride.stopId),
                arriveTime_planned:new Date(ride.arriveTime_planned),
                statusTime:new Date(ride.statusTime),
              }));
        },
        SetCorporate(state,data){
            state.corporate=[];
            state.corporate=data;
        },
        SetCorporatesRoutes(state, data) {
            state.corporateRoutes = [];
            state.corporateRoutes = data;
        },
        SetCorporatesEmployees(state, data) {
            state.corporateEmpolyees = [];
            state.corporateEmpolyees = data;
        },
        SetCorporatesBranchs(state, data) {
            state.corporateBranchs = [];
            state.corporateBranchs = data;
        },
        SetCorporatesMissingTrips(state, data) {
            state.corporateMissingTrips = [];
            state.corporateMissingTrips = data;
        },
        SetCorporatesRides(state, data) {
            state.corporateRides = [];
            state.corporateRides = data;
        },  
        SetLateTrips(state, data) {
            state.LateTrips = [];
            state.LateTrips = data.map(ride => ({
                ...ride,
                rideDate:new Date(ride.rideDate),
                startTime:new Date(ride.startTime),
              }));
        },  


    },
    actions: {       
        getCorporatesDetail({ commit }, content) {
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getCorporatesDetail`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("SetCorporatesDetail", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        }, 
        getEmployeeBooking({ commit }, content) {
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getEmployeeBooking`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {

                        if (res.status == 200) {
                            commit("SetEmployeeBooking", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
        getCorporatesByCorporateId({ commit }) {
           
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getCorporatesByCorporateId`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("SetCorporate", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
        getCorporateRoutes({ commit }) {
           
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getCorporateRoutes`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("SetCorporatesRoutes", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
        getCorporateEmployees({ commit }) {
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getCorporateEmployees`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("SetCorporatesEmployees", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
        getAllBranchesByCorporateId({ commit }) {
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `branches/getAllBranchesByCorporateId`,
                        {},
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res);

                        commit("SetCorporatesBranchs", res.data);
                    })
                    .catch((err) => console.log(err));
            });
        },
        getMissingEmployee({ commit }) {
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getMissingEmployee`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("getCorporateMissingTrips", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
        getCorporateRides({ commit }) {
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getCorporateRides`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("SetCorporatesRides", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
        getCorporateLateTrips({ commit }) {
            return new Promise((resolve, rej) => {
                api().post(`/CorporatesDetail/getCorporateLateTrips`,
                    { },{
                        headers: {
                            'Authorization': localStorage.getItem("JwtToken")
                        }
                    }
                )
                    .then(res => {
                        if (res.status == 200) {
                            commit("SetLateTrips", res.data);
                            resolve(res)
                        } else {
                            resolve(res)
                        }
                    }
                    );
            });
        },
    }
}