import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    CorporateEmployees: [], PersonDoc:[]
  },
  getters: {
    getCorporateEmployees(state) {
      return state.CorporateEmployees;
    },getPersonDoc(state) {
      return state.PersonDoc;
    },
  },
  mutations: {
    SetCorporateEmployees(state, data) {
      state.CorporateEmployees = [];
      state.CorporateEmployees = data;
    },SetPersonDoc(state, data) {
      state.PersonDoc = [];
      state.PersonDoc = data;
    },
  },
  actions: {
    getCorporateEmployees({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `CorporateEmployees/getCorporateEmployees`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCorporateEmployees", res.data);
        })
        .catch((err) => console.log(err));});
    },

    getCorporateEmployeeById({ commit },id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `CorporateEmployees/getCorporateEmployeeById`,
          {id:id},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
                      resolve(res);

          commit("SetCorporateEmployees", res.data);
        })
        .catch((err) => console.log(err));});
    },

    
    fetchpersonDocBypersonID({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Employees/get_personDoc_by_personID`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetPersonDoc", res.data);
        })
        .catch((err) => console.log(err));});
    },



    addCorporateEmployee({ }, formData) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateEmployees/addCorporateEmployee`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editCorporateEmployee(
      { },
      formData
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateEmployees/editCorporateEmployee`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteCorporateEmployee({ }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateEmployees/deleteCorporateEmployee`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    UploadEmployeeFile({ }, Formdata) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `CorporateEmployees/UploadEmployeeFile`,
            Formdata,
            {
              headers: {
                'Content-Type': 'multipart/form-data',

                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    removeEmployeeFile({ }, {CorporateEmployeesId,docNameId}) {
  return new Promise((resolve, rej) => {
    api()
      .post(
        `CorporateEmployees/removeEmployeeFile`,
        { docNameId: docNameId,CorporateEmployeesId:CorporateEmployeesId },
        {
          headers: {
            Authorization: localStorage.getItem("JwtToken"),
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err.response);
      });
  });
},




    
  },
};
