import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    SopPersons: [],
    StopsBybranchId: [],
    StopPersons: [],
    RoutePersons: [],

  },
  getters: {
    getSopPersons(state) {
      return state.SopPersons;
    },getStopsBybranchId(state) {
      return state.StopsBybranchId;
    },getStopPersons(state) {
      return state.StopPersons;
    },getRoutePersons(state) {
      return state.RoutePersons;
    },
  },
  mutations: {
    SetSopPersons(state, data) {
      state.SopPersons = [];
      state.SopPersons = data;
    },SetStopsBybranchId(state, data) {
      state.StopsBybranchId = [];
      state.StopsBybranchId = data;
    },SetStopPersons(state, data) {
      state.StopPersons = [];
      state.StopPersons = data;
    },SetRoutePersons(state, data) {
      state.RoutePersons = [];
      state.RoutePersons = data;
    }
  },
  actions: {

    getpersonBybranchId({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/getpersonBybranchId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetSopPersons", res.data);
        })
        .catch((err) => console.log(err));});
    },


    getStopsBybranchId({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/getstopsBybranchId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          console.log(res.data);
          commit("SetStopsBybranchId", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getStopPersons({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/getStopPersons`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetStopPersons", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getroutePersons({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/getroutePersons`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetRoutePersons", res.data);
        })
        .catch((err) => console.log(err));});
    },
    addPersonToStop({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/addPersonToStop`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => console.log(err));});
    },
    removePersonFromStop({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/removePersonFromStop`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) =>  resolve(err.response));});
    },

    setRouteToPersonFromStop({ commit }, data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `stopPersons/setRouteToPersonFromStop`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) =>  resolve(err.response));});
    },

  },
};
