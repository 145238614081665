import { createStore } from "vuex";
import auth from "./auth/auth.module.js";
import countries from "./region/countries.module.js";
import governates from "./region/governates.module.js";
import areas from "./region/areas.module.js";
import stops from "./stops/stops.module.js";
import routes from "./routes/routes.module.js";
import routesCategory from "./routes/routesCategory.js";
// main Data
import busTypes from "./mainData/busTypes.module.js";
import color from "./mainData/color.module.js";
import DocName from "./mainData/DocName.module.js";
import makes from "./mainData/makes.module.js";
import models from "./mainData/models.module.js";
import vehicleType from "./mainData/vehicleType.module.js";
import year from "./mainData/year.module.js";
import bus from "./mainData/bus.module.js";
import RideCategory from "./mainData/RideCategory.module.js";
import RideCategoryFare from "./mainData/RideCategoryFare.module.js";
import schedule from "./schedule/schedule.module.js";
import lookups from "./mainData/lookups.module.js";
import captains from "./mainData/captains.module.js";
import BusCaptains from "./mainData/BusCaptains.module.js";
import contractors from "./mainData/contractors.module.js";
import ContractorCaptains from "./mainData/ContractorCaptains.module.js";
import users from "./admin/users.module.js";
import pages from "./admin/pages.module.js";
import groups from "./admin/groups.module.js";
import groupPages from "./admin/groupPages.module.js";
import menu from "./admin/menu.module.js";
import dummyRides from "./dummyRides.module.js";
import realRides from "./mainData/realRides.module.js";
import contractorPercentageDeal from "./mainData/contractorPercentageDeal.module.js";
import packagemodel from "./package/package.module.js";
import packageDetailsTxt from "./package/packageDetailsTxt.module.js";
import persons from "./mainData/persons.module.js";
import duductions from "./mainData/duductions.module.js";
import captainChangeReasions from "./mainData/captainChangeReasions.module.js";
import employees from "./mainData/employees.module.js";
import startEndReasons from "./mainData/startEndReasons.module.js";
import cancelReasons from "./mainData/cancelReasons.module.js";
import captainTransactions from "./mainData/captainTransactions.module.js";
import coustomerTransactions from "./coustomerTransactions/coustomerTransactions.module.js";
import user from "./mainData/user.module.js";
import contractorTransaction from "./contractorTransaction/contractorTransaction.js";
import notification from "./notification.js";
import chat from "./chat/chat.module.js";
import chatSupp from "./chat/chatSupp.module.js";
import chatTopics from "./chatTopics/chatTopics.module.js";
import scheduledRunSchedules from "./mainData/scheduledRunSchedules.module.js";
import bannedUsers from "./mainData/bannedUsers.module.js";
import userCancellationReason from "./mainData/userCancellationReason.js";
import compensation from "./mainData/compensation.module.js";
import contractorInvoice from "./contractorInvoice.module.js";
import Firebase from "./firebaseNotification/Firebase.module.js";
import customerNotes from "./customerNotes/customerNotes.module.js";
import captainNotes from "./captainNotes/captainNotes.module.js";
import routStops from "./routStops.js";
import Routers from "./admin/Routers.module.js";
import corporates from "./corporates/corporates.module.js";
import corporateEmployees from "./corporates/corporateEmployees.module.js";
import branches from "./corporates/branches.module.js";
import stopPersons from "./stops/stopPersons.module.js";
import corporateClients from "./corporates/corporateClients.module.js";
import contract from "./contracts/contract.module.js";
import contractDetails from "./contracts/contractDetails.module.js";
import routesMap from "./routesMap/routesMap.module.js";
import booking from "./booking/booking.module.js";
import SchudulesPersons from "./SchudulesPersons.js";
import corporatesDetail from "./admin/corporatesDetail.module.js";


export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    stops,
    countries,
    governates,
    areas,
    routes,
    routesCategory,
    // main Data
    vehicleType,
    bus,
    year,
    busTypes,
    color,
    DocName,
    makes,
    models,
    RideCategory,
    RideCategoryFare,
    BusCaptains,
    ContractorCaptains,
    lookups,
    captains,
    contractors,
    contractorPercentageDeal,
    schedule,
    users,
    pages,
    groups,
    groupPages,
    menu,
    dummyRides,
    realRides,
    packagemodel,
    packageDetailsTxt,
    persons,
    duductions,
    captainChangeReasions,
    employees,
    startEndReasons,
    cancelReasons,
    captainTransactions,
    coustomerTransactions,
    user,
    contractorTransaction,
    notification,
    chat,
    chatSupp,
    scheduledRunSchedules,
    bannedUsers,
    chatTopics,
    userCancellationReason,
    compensation,
    contractorInvoice,
    Firebase,
    customerNotes,
    captainNotes,
    routStops,
    Routers,
    corporates,
    corporateEmployees,
    branches,
    stopPersons,
    corporateClients,
    contract,
    contractDetails,
    routesMap,
    booking,
    SchudulesPersons,
    corporatesDetail
  },
});
