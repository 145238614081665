import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    ShedulesPerson: [],
    PersonsByBranchs: [],
    StopsByroute: [],

  },
  getters: {
    getShedulesPerson(state) {
      return state.ShedulesPerson;
    }, getPersonsByBranchs(state) {
      return state.PersonsByBranchs;
    }, getStopsByroute(state) {
      return state.StopsByroute;
    },
  },
  mutations: {
    setShedulesPersons(state, data) {
      state.ShedulesPerson = [];
      state.ShedulesPerson = data;
    }, setPersonsByBranchs(state, data) {
      state.PersonsByBranchs = [];
      state.PersonsByBranchs = data;
    },setStopsByroute(state, data) {
      state.StopsByroute = [];
      state.StopsByroute = data;
    },
  },
  actions: {
    getShedulesPersonByscheduleId({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `SchudulesPersons/getShedulesPersonByscheduleId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("setShedulesPersons", res.data);
        })
        .catch((err) => console.log(err));})
    },

    getPersonsByBranchIds({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `SchudulesPersons/getPersonsByBranchIds`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("setPersonsByBranchs", res.data);
        })
        .catch((err) => console.log(err));})
    },
    GetStopsByroutId({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `SchudulesPersons/GetStopsByroutId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("setStopsByroute", res.data);
        })
        .catch((err) => console.log(err));})
    },
    
    addSchedulesPerson({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SchudulesPersons/addSchedulesPerson`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    upgradeSchedulesPerson({ dispatch }, data) {
      console.log(data);
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SchudulesPersons/upgradeSchedulesPerson`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editSchedulesPerson(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SchudulesPersons/editSchedulesPerson`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    DeletSchedulesPerson({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `SchudulesPersons/DeletSchedulesPerson`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    
  },
};
