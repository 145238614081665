import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Corporates:[],
    Branchs:[],
    Genders: [],
    SupplyTypes: [],
    Religines: [],
    Cities: [],
    MartialStatuse:[],
    PersonTypes:[],
    PaymentTypes:[],
     Routs:[],
     AllNotification:[],


  },
  getters: {
    getCorporates(state){
      return state.Corporates;
    },
    getBranchs(state){
      return state.Branchs;
    },
    getGenders(state) {
      return state.Genders;
    },
    getSupplyTypes(state) {
      return state.SupplyTypes;
    },
    getReligines(state) {
      return state.Religines;
    },getCities(state) {
      return state.Cities;
    },getMartialStatuse(state) {
      return state.MartialStatuse;
    },getPersonTypes(state) {
      return state.PersonTypes;
    },getPaymentTypes(state) {
      return state.PaymentTypes;
    },getRouts(state) {
      return state.Routs;
    },getAllNotification(state) {
      return state.AllNotification;
    },
  },
  mutations: {

    SetCorporates(state, data) {
      state.Corporates = [];
      state.Corporates = data;
    },
    SetBranchs(state, data) {
      state.Branchs = [];
      state.Branchs = data;
    },
    SetGenders(state, data) {
      state.Genders = [];
      state.Genders = data;
    },
    SetSupplyTypes(state, data) {
      state.SupplyTypes = [];
      state.SupplyTypes = data;
    },
    SetReligines(state, data) {
      state.Religines = [];
      state.Religines = data;
    },SetCities(state, data) {
      state.Cities = [];
      state.Cities = data;
    },SetMartialStatuse(state, data) {
      state.MartialStatuse = [];
      state.MartialStatuse = data;
    },SetPersonTypes(state, data) {
      state.PersonTypes = [];
      state.PersonTypes = data;
    },SetPaymentTypes(state, data) {
      state.PaymentTypes = [];
      state.PaymentTypes = data;
    },SetRouts(state, data) {
      state.Routs = [];
      state.Routs = data;
    },SetAllNotification(state, data) {
      state.AllNotification = [];
      state.AllNotification = data;
    },
  },
  actions: {
    fetchAllCorporates({ commit }) {
      api()
        .post(
          `lookups/get_all_Corporates`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetCorporates", res.data);
        })
        .catch((err) => console.log(err));
    },
    fetchAllBranchs({ commit }) {
      api()
        .post(
          `lookups/get_all_Branchs`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetBranchs", res.data);
        })
        .catch((err) => console.log(err));
    },
    fetchAllGenders({ commit }) {
      api()
        .post(
          `lookups/get_all_Genders`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetGenders", res.data);
        })
        .catch((err) => console.log(err));
    },
    fetchAllSupplyTypes({ commit }) {
      api()
        .post(
          `lookups/get_all_supplyTypes`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetSupplyTypes", res.data);
        })
        .catch((err) => console.log(err));
    },
    fetchAllReligines({ commit }) {
      api()
        .post(
          `lookups/get_all_religines`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          commit("SetReligines", res.data);
        })
        .catch((err) => console.log(err));
    },

    fetchAllMartialStatuses({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `lookups/get_all_martialStatuses`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetMartialStatuse", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getPersonTypes({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `lookups/getPersonTypes`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetPersonTypes", res.data);
        })
        .catch((err) => console.log(err));});
    },
    fetchAllCitiesByGovernateID({ commit }, governateID) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `lookups/get_all_cities_by_governateID`,
          {
            governateID: governateID,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetCities", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getpaymentTypes({ commit }, ) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `lookups/getpaymentTypes`,
          {  },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetPaymentTypes", res.data);
        })
        .catch((err) => console.log(err));});
    }, 
    getRouts({ commit }, ) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `lookups/getRouts`,
          {  },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetRouts", res.data);
        })
        .catch((err) => console.log(err));});
    }, getAllNotification({ commit }, ) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `lookups/getAllNotification`,
          {  },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetAllNotification", res.data);
        })
        .catch((err) => console.log(err));});
    },
  },
};
