import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    contractDetails: [],
  },
  getters: {
    getcontractDetails(state) {
      return state.contractDetails;
    },
  },
  mutations: {
    SetcontractDetails(state, data) {
      state.contractDetails = [];
      state.contractDetails = data
    },
  },
  actions: {
    getcontractesBycontractId({ commit },data) {
    
      return new Promise((resolve, rej) => {  api()
        .post(
          `contractDetails/getcontractesBycontractId`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetcontractDetails", res.data);
        })
        .catch((err) => console.log(err));});
    },


    addcontractDetails({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractDetails/addcontractDetails`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            
            resolve(err.response);
          });
      });
    },
    deletecontractDetails(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contractDetails/deletecontractDetails`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


  },
};
