import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    Branches: [],
  },
  getters: {
    getBranches(state) {
      return state.Branches;
    },
  },
  mutations: {
    SetBranches(state, data) {
      state.Branches = [];
      state.Branches = data;
    },
  },
  actions: {
    fetchAllBranches({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Branches/getbranches`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBranches", res.data);
        })
        .catch((err) => console.log(err));})
    },
    fetchBranchesById({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `Branches/getbranchByid`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBranches", res.data);
        })
        .catch((err) => console.log(err));});
    },
    getBranchesByCorporateId({ commit }, id) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `branches/getBranchesByCorporateId`,
          {
            corporateId: id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetBranches", res.data);
        })
        .catch((err) => console.log(err));});
    },


    addBranches({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Branches/addbranch`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    editBranches(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Branches/editbranch`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deleteBranches({ dispatch }, id) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `Branches/deletebranch`,
            { id: id },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {

            resolve(res);

          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
