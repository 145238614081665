import api from "../../api";
/* eslint-disable */
export default {
    namespaced: true,
    state: {
        upcomingRides: [],
        compeletedRides: [],
        missedRides: [],
        bookingRides:[],
    },
    getters: {
        getUpcomingRides(state) {
            return state.upcomingRides;
        },
        getCompeletedRides(state) {
            return state.compeletedRides;
        },
        getMissedRides(state) {
            return state.missedRides;
        },
        getBookingRides(state) {
            return state.bookingRides;
        },
    },
    mutations: {
        SetUpcomingRides(state, data) {
            state.upcomingRides = [];
            state.upcomingRides = data;
        },
        SetCompeletedRides(state, data) {
            state.compeletedRides = [];
            state.compeletedRides = data;
        },
        SetMissedRides(state, data) {
            state.missedRides = [];
            state.missedRides = data;
        },
        SetBookingRides(state, data) {
            state.bookingRides = [];
            state.bookingRides = data;
        },
    },
    actions: {

        fetchAllBookingByRealrideId({ commit },data) {
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `Booking/getBookingByRealrideId`,
                        data,
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res);
                        commit("SetBookingRides", res.data.data);
                    })
                    .catch((err) => console.log(err));
            });
        },
        getUpComingRidesByPersonId({ commit },data) {
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `bookingDetails/upComingRidesByPersonId`,
                        {id:data},
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res);

                        commit("SetUpcomingRides", res.data);
                    })
                    .catch((err) => console.log(err));
            });
        },
        getCompeletedRidesByPersonId({ commit },data) {
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `bookingDetails/completedRidesByPersonId`,
                        {id:data},
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res);

                        commit("SetCompeletedRides", res.data);
                    })
                    .catch((err) => console.log(err));
            });
        },
        getMissedRidesByPersonId({ commit },data) {
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `bookingDetails/missedRidesByPersonId`,
                        {id:data},
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve(res);

                        commit("SetMissedRides", res.data);
                    })
                    .catch((err) => console.log(err));
            });
        },
        cancelUpcomingBooking({ dispatch }, bookingId) {
            console.log(bookingId);
            return new Promise((resolve, rej) => {
              api()
                .post(
                  `bookingDetails/cancelUpcomingBooking`,
                  { id: bookingId },
                  {
                    headers: {
                      Authorization: localStorage.getItem("JwtToken"),
                    },
                  }
                )
                .then((res) => {
      
                  resolve(res);
      
                })
                .catch((err) => {
                  resolve(err.response);
                });
            });
          },

          cancelBook({  }, data) {
            return new Promise((resolve, rej) => {
              api()
                .post(
                  `Booking/cancelBook`,
                  data,
                  {
                    headers: {
                      Authorization: localStorage.getItem("JwtToken"),
                    },
                  }
                )
                .then((res) => {
                
                  resolve(res);
                })
                .catch((err) => {
                  resolve(err.response);
                });
            });
          },



        

       

       

        
    },
};
