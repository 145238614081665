import api from "../../api";
export default {
    namespaced: true,
    state: {
        routes: [],
        branchs: [],
        corporateClients:[],
        routesStops:[],
    },
    getters: {
        getRoutes(state) {
            return state.routes;
        },
        getBranchs(state) {
            return state.branchs;
        },
        getCorporateClients(state) {
            return state.corporateClients;
        },
        getRoutesStops(state) {
            return state.routesStops;
        },
    },
    mutations: {
        SetRoutes(state, data) {
            state.routes = [];
            state.routes = data;
        },
        SetBranchs(state,data){
            state.branchs=[];
            state.branchs=data;
        },
        SetCorporateClients(state,data){
            state.corporateClients=[];
            state.corporateClients=data;
        },
        SetRoutesStops(state, data) {
            state.routesStops = [];
            state.routesStops = data;
        },
    },
    actions: {
        fetchAllRoutesByCorporateId({ commit }) {
           
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `routes/getRoutesByCorporateId`,
                        {},
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve();
                        commit("SetRoutes", res.data);
                    })
                    .catch((err) => {

                        console.log(err)
                    });
            })
        },
        getAllBranchesByCorporateId({ commit }) {
            return new Promise((resolve, rej) => {
            api()
              .post(
                `branches/GetAllBranchesByCorporateId`,
                {},
                {
                  headers: {
                    Authorization: localStorage.getItem("JwtToken"),
                  },
                }
              )
              .then((res) => {
                resolve(res);
                
                commit("SetBranchs", res.data);
              })
              .catch((err) => console.log(err));});
          },
          fetchAllCorporateClientsByBranchsIds({ commit },data) {
     
            
            return new Promise((resolve, rej) => {
                api()
                    .post(
                        `CorporateClients/getCorporateClientsByBranchsIds`,
                        data,
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                       
                        resolve();
                        commit("SetCorporateClients", res.data);
                    })
                    .catch((err) => {

                        console.log(err)
                        rej(err);
                    });
            })
        },

        fetchAllrouteStopsByrouteIds({ commit },{routeIds,branchsIds,corporateClientsIds}) {
     
            
            return new Promise((resolve, rej) => {
                const requestData = {
                    routesIds: routeIds.value,
                    branchsIds: branchsIds.value,
                    corporateClientsIds: corporateClientsIds.value
                };
                
                api()
                    .post(
                        `routes/getrouteStopsByrouteIds`,
                        requestData,
                        {
                            headers: {
                                Authorization: localStorage.getItem("JwtToken"),
                            },
                        }
                    )
                    .then((res) => {
                       
                        resolve();
                        commit("SetRoutesStops", res.data);
                    })
                    .catch((err) => {

                        console.log(err)
                        rej(err);
                    });
            })
        },

    }
}