import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    contracts: [],
  },
  getters: {
    getcontracts(state) {
      return state.contracts;
    },
  },
  mutations: {
    Setcontracts(state, data) {
      state.contracts = [];
      state.contracts = data.map(ride => ({
        ...ride,
        fromDate: new Date(ride.fromDate),
        toDate:ride.toDate==null?null: new Date(ride.toDate), 
        corporateId: Number(ride.corporateId), 

      }));
    },
  },
  actions: {
    getcontractes({ commit }) {
    
      return new Promise((resolve, rej) => {  api()
        .post(
          `contracts/getcontractes`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("Setcontracts", res.data);
        })
        .catch((err) => console.log(err));});
    },


    addcontract({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contracts/addcontract`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            
            resolve(err.response);
          });
      });
    },
    editcontract(
      { dispatch },
      data
    ) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contracts/editcontract`,
            data,
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },

    deletecontract({ dispatch }, serial) {
      return new Promise((resolve, rej) => {
        api()
          .post(
            `contracts/deletecontract`,
            { serial: serial },
            {
              headers: {
                Authorization: localStorage.getItem("JwtToken"),
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
  },
};
